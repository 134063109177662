import { TAuditListData } from "@/helpers/api/audit-list/types";
import { Loader } from "@/packages/loader/Loader";
import { Pagination } from "@/packages/pagination/Pagination";
import { Paragraph } from "@/packages/paragraph/Paragraph";

import { ITableSimple, TableSimple } from "@/packages/tableSimple/TableSimple";
import React, { useEffect } from "react";

import { getDefaultAuditTableHeaders } from "./helpers";
import { StyledTableSimple } from "./index.styles";
import { useTranslation } from "react-i18next";
import { useProfile } from "@/hooks/contexts/useProfile";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";

interface IAuditListTable extends Pick<ITableSimple, "onSort"> {
  data?: TAuditListData[];
  isLoading?: boolean;
  paginationParams: { page: number; limit: number };
  openCopyModal: (id: number) => void;
  hasPagination?: boolean;
  setPage: (page: number) => void;
  onViewClick: (path: string) => void;
  onDelete: (id: number) => void;
  onArchive: (id: number) => void;
  requestParams: any;
}
export const AuditListTable: React.FC<IAuditListTable> = (props) => {
  const {
    data,
    isLoading,
    hasPagination,
    paginationParams,
    setPage,
    openCopyModal,
    onSort,
    onViewClick,
    onDelete,
    onArchive,
    requestParams,
  } = props;

  const {
    state: { profile },
  } = useProfile();

  const { dispatch: openUserModal } = useUserInfoModel();

  const headers = getDefaultAuditTableHeaders({
    onDeleteClick: onDelete,
    openCopyModal,
    onViewClick,
    onArchive,
    profile,
    openUserModal,
  });
  const { t } = useTranslation();

  return (
    <>
      <StyledTableSimple>
        {!isLoading ? (
          data?.length ? (
            <>
              <TableSimple
                alignHeader="flex-start"
                headers={headers || []}
                data={
                  data?.map((item) => ({
                    ...item,
                    isArchived: item.status === "В архиве",
                  })) || []
                }
                onSort={onSort}
                defaultColumn={{
                  minWidth: 100,
                  width: 155,
                  maxWidth: 300,
                }}
              />
              {hasPagination ? (
                <Pagination
                  current={paginationParams.page}
                  onChange={setPage}
                  pageSize={1}
                  total={paginationParams.limit}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <Paragraph size={16}>{t("common.noData") || ""}</Paragraph>
          )
        ) : (
          <>
            <Loader size={48} />
          </>
        )}
      </StyledTableSimple>
    </>
  );
};
