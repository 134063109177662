import styled from 'styled-components';
import { SelectField } from '@/packages/formElements/fields/SelectField';

export const StyledAutoParkDescWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 40px 14px 22px;
`;

export const StyledAutoParkDesc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const StyledSelect = styled(SelectField)`
    width: 300px;
`;
