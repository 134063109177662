import { FormSelect } from "@/packages/formElements/formFields/FormSelect";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Title } from "@/packages/title/Title";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Col, Row } from "antd";
import React from "react";
import { FormProvider } from "react-hook-form";
import {
  IMonitoringCarsFilterFormValues,
  IMonitoringCarsFilterOptions,
  MonitoringCarsFilterFormFieldsNameEnum,
  TOnSubmitMonitoringFilterFormCb,
  useMonitoringCarsFilterForm,
} from "./hooks";
import { StyledMonitoringFilterWrapper, StyledTitleContainer, } from "./index.styles";
import { t } from "i18next";
import {
  StyledButtonsContainer,
  StyledCheckboxWrapper
} from "@/components/MonitoringQuality/MonitoringFilter/index.styles";
import { Button } from "@/packages/button/Button";
import { FormCheckbox } from "@/packages/formElements/formFields/FormCheckbox";
import { MonitoringFilterFormFieldsNameEnum } from "@/components/MonitoringQuality/MonitoringFilter/hooks";

export interface IMonitoringCarsFilter {
  initValues: IMonitoringCarsFilterFormValues | any;
  filterOptions?: IMonitoringCarsFilterOptions;
  onFiltersChange: TOnSubmitMonitoringFilterFormCb;
  handleExport: () => void;
  resetPage: () => void;
}

export const MonitoringCarsFilter: React.FC<IMonitoringCarsFilter> = (
  props
) => {
  const { initValues, filterOptions, onFiltersChange, handleExport, resetPage } = props;
  const { form, submit, reset } = useMonitoringCarsFilterForm({
    initValues,
    onSubmit: onFiltersChange,
  });

  const handleReset = () => {
    reset();
    submit && submit();
  };
  const handleSubmit = () => {
    resetPage();
    submit && submit();
  };

  return (
    <StyledMonitoringFilterWrapper>
      <StyledTitleContainer>
        <Title level={3} weight={400}>
          {t("monitoring.cars") || ""}
        </Title>
        <Tooltip content={t("monitoring.exportExcell") || ""}>
          <IconButton
            icon="save"
            isSquared
            variant="primary"
            color="white"
            size="m"
            onClick={handleExport}
          />
        </Tooltip>
      </StyledTitleContainer>
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={6}>
            <FormSelect
              name={MonitoringCarsFilterFormFieldsNameEnum.CityIds}
              label={t("monitoring.city") || ""}
              placeholder={t("monitoring.all") || ""}
              isSearchShow
              options={filterOptions?.cities}
              allowClear
            />
          </Col>

          <Col span={6}>
            <FormSelect
              name={MonitoringCarsFilterFormFieldsNameEnum.DealerIds}
              label={t("monitoring.dealership") || ""}
              options={filterOptions?.dealers}
              isSearchShow
              placeholder={t("monitoring.all") || ""}
              allowClear
            />
          </Col>

          <Col span={4}>
            <FormSelect
              name={MonitoringCarsFilterFormFieldsNameEnum.Vins}
              label="VIN"
              options={filterOptions?.vins}
              isSearchShow={true}
              placeholder={t("monitoring.all") || ""}
              allowClear
            />
          </Col>
          <Col span={4}>
            <FormSelect
              name={MonitoringCarsFilterFormFieldsNameEnum.GenerationIds}
              label={t("park.model") || ""}
              placeholder={t("monitoring.all") || ""}
              options={filterOptions?.generations}
              isSearchShow
              allowClear
            />
          </Col>
        </Row>
        <Row gutter={16}>
          <StyledCheckboxWrapper>
            <FormCheckbox
              name={MonitoringFilterFormFieldsNameEnum.OnlyUnreadMessages}
              label={'Только непрочитанные сообщения'}
            />
          </StyledCheckboxWrapper>
        </Row>
      </FormProvider>
      <StyledButtonsContainer>
        <Button color="brand" theme="primary" size="middle" onClick={handleSubmit}>
          {t("monitoring.find") || ""}
        </Button>
        <Button
          color="brand"
          theme="secondary"
          size="middle"
          onClick={handleReset}
        >
          {t("monitoring.clear") || ""}
        </Button>
      </StyledButtonsContainer>
    </StyledMonitoringFilterWrapper>
  );
};
