import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { IRequestParams } from "@/helpers/interfaces";
import { requestParamsMapper } from "@/helpers/utils";

import { api } from "../axiosInstance";
import {
  TGetVehicleListRequest,
  // TGetVehicleListResponse,
  TGetVehicleByIdRequest,
  TGetVehicleByIdResponse,
  TCreateVehicleRequest,
  TCreateVehicleResponse,
  TPutVehicleRequest,
  TPatchToArchiveVehicleRequest,
  // TPatchToArchiveVehicleResponse,
  TPatchFromArchiveVehicleRequest, TGetFiltersVehicleResponse,
  // TPatchFromArchiveVehicleResponse
} from './types';

export const useGetVehicleList = (request: TGetVehicleListRequest) => {
  return useQuery(["vehicles", "list", request], () => {
    // TODO: response
    return api.get<any>("/test-drives/vehicles", {
      // @ts-ignore
      params: requestParamsMapper(request as IRequestParams),
    });
  });
};

export const useGetVehicleParkList = (request: TGetVehicleListRequest) => {
  return useQuery(["vehicles", "list", request], () => {
    // TODO: response
    return api.get<any>("/test-drives/vehicles/park", {
      // @ts-ignore
      params: request,
    });
  });
};

export const useGetVehicleById = (request: TGetVehicleByIdRequest) => {
  return useQuery(["vehicleById", request.id], () => {
    return api.get<TGetVehicleByIdResponse>(
      `/test-drives/vehicles/${request.id}`
    );
  });
};

export const useCreateVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TCreateVehicleRequest) => {
      return api.post<TCreateVehicleResponse>("/test-drives/vehicles", data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["vehicles", "list"]);
      },
    }
  );
};

export const usePatchVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, put }: TPutVehicleRequest) => {
      // TODO: response
      return api.patch<any>(`/test-drives/vehicles/${id}`, put);
    },
    {
      onSuccess: (resp, { id }) => {
        queryClient.invalidateQueries(["vehicles", "list"]);
        queryClient.invalidateQueries(["vehicleById", id]);
        queryClient.invalidateQueries(["test-drives", "calendar"]);
      },
    }
  );
};

export const usePatchToArchiveVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: TPatchToArchiveVehicleRequest) => {
      // TODO: response
      return api.patch<any>(`/test-drives/vehicles/${id}/archive`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["vehicles", "list"]);
      },
    }
  );
};

export const usePatchFromArchiveVehicle = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ id }: TPatchFromArchiveVehicleRequest) => {
      // TODO: response
      return api.patch<any>(`/test-drives/vehicles/${id}/recover-from-archive`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["vehicles", "list"]);
      },
    }
  );
};

export const useGetVehicleFiltersList = () => {
  return useQuery(["vehicles", "filters"], () => {
    return api.get<TGetFiltersVehicleResponse>("/test-drives/vehicles/filters");
  });
};
