import { TGetProfileResponse, TRoleSlug, } from "@/helpers/api/profile/types";
import { IRequestParams, TRequestValues } from "@/helpers/interfaces";
import { NavMenuItem } from "@/layouts/DashboardLayout/MainLayoutNavBar";
import { ISelectOption } from "@/packages/formElements/inputs/Select";
import { OptionType } from "@/helpers/types";
import { TFunction } from "i18next";

export const requestParamsMapper = <T>(
  params?: IRequestParams | T
): { [key: string]: string | number | null } | {} => {
  if (!params) return {};
  const newParams: {
    [key: string]: TRequestValues;
  } = {};
  Object.entries(params).forEach(([name, param]) => {
    if (
      typeof param.value !== undefined &&
      (!!param.value || param.value === 0 || param.value === false)
    ) {
      if (param.type) {
        newParams[`${param.type}[${name}]`] = param.value;
      } else {
        newParams[name] = param.value;
      }
    }
  });

  return newParams;
};

export function isUniqueArray<T>(array: T[] | undefined): boolean {
  if (!Array.isArray(array)) {
    return false;
  }
  return array.length === 1
}

export const getAdaptSelectOptions = ({
                                        options,
                                        labelKey = "name",
                                        labelKey2 = "restylingName",
                                        valueKey = "id",
                                        optionInValue = false,
                                      }: {
  options: any[];
  labelKey?: string;
  labelKey2?: string;
  valueKey?: string;
  optionInValue?: boolean;
}): ISelectOption[] => {
  return options.map((option) => ({
    ...option,
    label: `${option[labelKey]} ${option[labelKey2] ? option[labelKey2] : ""}`,
    value: optionInValue ? JSON.stringify(option) : option[valueKey],
  }));
};

export const getSumArrayNumbers = (arr: any[], currentKey?: string) =>
  arr.reduce(
    (acc, current) => acc + (currentKey ? current[currentKey] : current),
    0
  );

export const getNavItems = (
  t: TFunction,
  profile?: TGetProfileResponse
): NavMenuItem[] => {
  const dealerId = profile?.dealer?.id;
  const isSuperAdmin = profile?.role?.slug === "super-admin";
  const isKiaAdmin = profile?.role?.slug === "kia-admin";

  return [
    {
      permission: "admin.user",
      key: "admin",
      icon: "admin-nav",
      label: 'Администрирование порталом',
      path: "/admin",
    },
    {
      permission: "news",
      key: "news",
      icon: "news",
      label: t("news.title"),
      path: "/news",
    },
    {
      key: "dc",
      icon: "list-dc",
      label: t("registers.title"),
      children: [
        {
          permission: "dc",
          key: "dc",
          icon: "info",
          label: t("registers.registersDC.title"),
          path: "/registry",
        },

        {
          permission: "holding",
          key: "holding",
          icon: "info",
          label: t("registers.holdingsDC.title"),
          path: "/holdings",
        },
      ],
    },
    {
      permission: "dc.card",
      key: "card-dc",
      icon: "dc",
      label: t("cardDC.title"),
      path: `/card-dc/${dealerId}`,
    },
    {
      key: "dealers-materials",
      label: t("dealerMaterials.title"),
      icon: "materials-nav",
      children: [
        {
          permission: "information-letters",
          key: "information-letters",
          icon: "ip-nav-icon",
          label: t("dealerMaterials.letters.title"),
          path: "/information-letters",
        },
        {
          permission: "materials",
          key: "materials",
          icon: "materials",
          label: t("dealerMaterials.materials.title"),
          path: "/materials",
        },
      ],
    },
    {
      permission: "model",
      key: "model",
      icon: "car",
      label: t("models.title"),
      children: [
        {
          permission: "model",
          path: "/models",
          label: t("models.title"),
          key: 'models-auto',
          icon: "",
        },
        {
          permission: "part",
          key: "part",
          label: t("parts.title"),
          path: "/parts",
          icon: "",
        },
      ],
    },
    {
      permission: "maintenance-grid",
      key: "maintenance-grid",
      icon: "parts",
      label: t("maintenanceGrids.title"),
      path: "/maintenance-grids",
    },
    {
      key: "vin-decoder",
      permission: "decoder",
      icon: "search-16",
      label: t("vin-decoder.title"),
      path: "/vin-decoder",
    },
    {
      key: "audit",
      label: t("audits.title"),
      icon: "audit-ppo-nav",
      children: [
        {
          permission: ["audit.list", "audit.list-dealer", "audit.list-holding"],
          key: "audits.list",
          icon: "",
          label: t("audits.list.title"),
          path: "/audit-list",
        },
        {
          permission: [
            "audit.task-managment",
            "audit.task-implementation",
            "audit.tasks-for-dealer",
            "audit.tasks-for-holding",
          ],
          key: "audit.tasks-managment",
          icon: "",
          label: t("audits.tasks.title"),
          path: "/audit-tasks",
        },
        {
          permission: ["audit.templates"],
          key: "audit.templates",
          icon: "",
          label: t("audits.templates.title"),
          path: "/audit-templates",
        },
      ],
    },
    {
      key: "audits",
      label: t("warrantyAudits.title"),
      icon: "warranty-nav",
      children: [
        {
          permission: [
            "guarantee.audits-list",
            // "guarantee.audits-implementation" ?? это удалить
            // "guarantee.audits-list-dealer", пока нет таких
            // "guarantee.audits-list-holding",
          ],
          key: "guarantee.audits-list",
          icon: "",
          label: t("warrantyAudits.list.title"),
          path: "/warranty-audit-list",
        },
        {
          permission: "guarantee.templates",
          key: "guarantee.templates",
          icon: "",
          label: t("warrantyAudits.templates.titleColumn"),
          path: "/warranty-audit-templates",
        },
        {
          permission: [
            "guarantee.task-review",
            "guarantee.task-implementation",
            "guarantee.tasks-for-dealer",
            "guarantee.tasks-for-holding",
          ],
          key: "guarantee.task-review",
          icon: "",
          label: t("warrantyAudits.tasks.title"),
          path: "/warranty-audit/tasks",
        },
      ],
    },
    {
      permission: "quality-monitoring",
      key: "quality-monitoring",
      icon: "quality-monitoring-nav",
      label: t("monitoring.title"),
      path: "/monitoring-quality",
    },
    {
      permission: "transport_claims",
      key: "transport_claims",
      icon: "transport-claims-nav",
      label: t("complaints.title"),
      path: "/transport-claims",
    },
    {
      key: "portal-bonuses",
      icon: "bonuses-nav",
      label: t("bonuses.title"),
      children: [
        {
          permission: isKiaAdmin || isSuperAdmin ? [
            "bonuses.reports",
            "bonuses.reports-dc",
            "bonuses.reports-holding",
          ] : ["hide-section"],
          key: "bonuses-reports-view",
          icon: "",
          label: t("bonuses.reportsView.title"),
          path: "/bonuses/reports-view",
        },
        {
          permission: isKiaAdmin || isSuperAdmin ? "bonuses.reports-files" : "hide-section",
          key: "bonuses-reports-imports",
          icon: "",
          label: t("bonuses.reportsImport.title"),
          path: "/bonuses/reports-import",
        },
        {
          permission: isKiaAdmin || isSuperAdmin ? "bonuses.reports-files" : "hide-section",
          key: "bonuses-calculation",
          icon: "",
          label: t("bonuses.calculations.title"),
          path: "/bonuses/calculation",
        },
        {
          permission: isKiaAdmin || isSuperAdmin ? "bonuses.payout" : "hide-section",
          key: "bonuses-premium",
          icon: "",
          label: t("bonuses.premium.title"),
          path: "/bonuses/premium",
        },
      ],
    },
    {
      key: "park",
      icon: "gear-nav",
      label: t("park.title"),
      children: [
        {
          permission: "test-drives",
          key: "test-drives",
          icon: "",
          label: t("park.subTitle_1"),
          path: "/calendar",
        },
        {
          permission: "test-drives",
          key: "test-drives",
          icon: "",
          label: t("park.subTitle_2"),
          path: "/autopark",
        },
        {
          permission: "test-drives",
          key: "test-drives",
          icon: "",
          label: t("park.subTitle_3"),
          path: "/applications",
        },
        {
          permission: "test-drives",
          key: "test-drives",
          icon: "",
          label: t("park.subTitle_4"),
          path: "/statistics",
        },
        {
          permission: "test-drives",
          key: "test-drives",
          icon: "",
          label: t("park.subTitle_5"),
          path: "/mediabase",
        },
      ],
    },
    {
      key: "flex",
      icon: "flex",
      label: t("kiaFlex.title"),
      children: [
        {
          permission: ["flex.quotas"],
          key: "flex.quotas",
          icon: "",
          label: t("kiaFlex.subTitle_1"),
          path: "/flex/settings",
        },
        {
          key: "flex.rent",
          permission: "flex.rent",
          icon: "",
          label: t("kiaFlex.subTitle_2"),
          path: "/flex/rent-status",
        },
        {
          permission: "flex.idle",
          key: "flex.idle",
          icon: "",
          label: t("kiaFlex.subTitle_3"),
          path: "/flex/idle-catalog",
        },
        {
          permission: "flex.vehicle",
          key: "flex.vehicle",
          icon: "",
          label: t("kiaFlex.subTitle_4"),
          path: "/flex/cars",
        },
      ],
    },
  ];
};

export const isHeadUserBySlug = (slug?: TRoleSlug) => {
  const headUserSlugs = ["generalnyj-direktor"];

  return Boolean(slug && headUserSlugs.includes(slug));
};

export const isAdminUserBySlug = (slug?: TRoleSlug) => {
  const headUserSlugs = ["super-admin"];

  return Boolean(slug && headUserSlugs.includes(slug));
};

export const isDate = (date: Date) =>
  date instanceof Date && !isNaN(date.valueOf());

export const getWordWithSuffix = (count: number, words: string[]): string => {
  const absoluteCount = (Math.abs(count) % 100);
  const restNumber = absoluteCount % 10;

  if (absoluteCount > 10 && absoluteCount < 20) return words[2];
  if (restNumber > 1 && restNumber < 5) return words[1];
  if (restNumber === 1) return words[0];
  return words[2];
};

export const deleteDuplicateInArray = <T extends { id: string }>(
  array: T[]
): T[] => {
  return array.reduce((o: T[], i) => {
    if (!o.find((v) => v.id === i.id)) {
      o.push(i);
    }
    return o;
  }, []);
};

export const mutateArrOfObj = (arrObj) => {
  if (!arrObj) {
    return arrObj;
  }
  const items = arrObj.map((obj) => {
    delete obj["children"];
    return obj;
  });
  for (const item of items) {
    const parent = arrObj.find(({ id }) => id === item.parentId);
    if (parent) {
      parent.children = parent.children ? [...parent.children, item] : [item];
    }
  }

  const res = items.filter((el) => el.parentId === null);

  function recursive(item, id, index) {
    let returnedItem = item;

    returnedItem.level = index;
    returnedItem.isChecked = false;

    if (item.children?.length) {
      returnedItem = {
        ...returnedItem,
        children: returnedItem.children.map((childItem, index) =>
          recursive(childItem, index + 1, `${returnedItem.level}.${index + 1}`)
        ),
      };
    }

    return {
      ...returnedItem,
      level: index,
    };
  }

  return res.map((item, index) => recursive(item, 0, `${index + 1}`));
};

export const responsibleToOptions = (responsible): OptionType => {
  return responsible?.data.map((el) => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));
};
export const formatDateToPeriod = (
  dateFrom: string,
  dateTo: string
): string => {
  if (dateFrom && dateTo) {
    return `
    ${new Date(dateFrom).toLocaleDateString()} - ${new Date(
      dateTo
    ).toLocaleDateString()}`;
  } else {
    return "";
  }
};

export const handleFileLoad = async (data: Blob, downloadLink: string) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(data);
  link.download = downloadLink;
  link.click();
};
