import { TAuditListData, TAuditStatuses } from "@/helpers/api/audit-list/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Popconfirm } from "antd";

import { StyledButtons } from "./index.styles";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { getScoreString } from "@/components/Audits/FillAuditStages/AuditInfo";
import { TGetProfileResponse } from "@/helpers/api/profile/types";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const MOCK_AUDIT_FILTERS = [
  { label: "Все", value: "" },
  { label: "Активный", value: "true" },
  { label: "Скрытый", value: "false" },
];

const getColor = (percent: number) => {
  if (percent < 20) {
    return "red";
  } else if (percent < 50) {
    return "yellow";
  } else {
    return "green";
  }
};

const PercentStatus: React.FC<{ value: number }> = (props) => {
  const { value } = props;
  if (!value) {
    return (
      <Paragraph size={12} weight={300}>
        Не начат
      </Paragraph>
    );
  } else
    return (
      <Paragraph size={12} weight={300} color={getColor(value)}>
        {value + "%"}
      </Paragraph>
    );
};

const SLUG_TO_STATUS: {
  [key in TAuditStatuses]: string;
} = {
  Активный: "Активный",
  Новый: "Новый",
  Закрытый: "Закрытый",
};

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  onDeleteClick?: (id: number) => void;
  openCopyModal: (id: number) => void;
  onViewClick: (path: string) => void;
  onArchive: (id: number) => void;
  profile?: TGetProfileResponse;
  openUserModal: Dispatch;
}): IHeaderItem<TAuditListData>[] => {
  const { onDeleteClick, openCopyModal, onViewClick, onArchive, openUserModal, profile } = options;

  return [
    {
      Header: "ID",
      accessor: "id",
      minWidth: 80,
      maxWidth: 80,
    },
    {
      Header: t("audits.list.status") || "",
      accessor: "status",
      id: "status",
      isSortable: true,
      minWidth: 90,
      maxWidth: 110,

      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={300}>
            {SLUG_TO_STATUS[value]}
          </Paragraph>
        );
      },
    },
    {
      Header: t("audits.list.titleTable") || "",
      accessor: "title",
      id: "title",
      isSortable: true,
      minWidth: 70,
      maxWidth: 140,
    },
    {
      Header: "Номер визита",
      accessor: "visitNumber",
      id: "visitNumber",
      minWidth: 70,
      maxWidth: 100,
    },
    {
      Header: t("audits.list.createdAt") || "",
      accessor: "createdAt",
      id: "createdAt",
      isSortable: true,
      minWidth: 70,
      maxWidth: 110,

      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={300}>
            {`${new Date(value).toLocaleDateString()} ${
              original.completedAt
                ? "-" +
                " " +
                new Date(original.completedAt).toLocaleDateString()
                : ""
            }`}
          </Paragraph>
        );
      },
    },

    {
      Header: t("audits.list.manager") || "",
      accessor: "manager",
      id: "manager",
      isSortable: true,
      minWidth: 70,
      maxWidth: 130,
      Cell: ({ row: { original }}) => {
        const name = `${original?.managerFirstName || ""}  ${
          original?.managerLastName || ""
        }`;
        return (
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original.managerId })}>
            {name}
          </StyledUserNameWrapper>)
      },
    },
    {
      Header: t("audits.list.dealer") || "",
      accessor: "dealer",
      isSortable: true,
      Cell: ({ row: { original }, value }) => {
        return `${original?.dealerName || ""}`;
      },
    },
    {
      Header: t("audits.list.responsible") || "",
      accessor: "responsible",
      id: "responsible",
      isSortable: true,
      Cell: ({ row: { original } }) => {
        return (
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original.responsibleId })}>
            {original.responsible}
          </StyledUserNameWrapper>)
      },
    },
    {
      Header: t("audits.list.percent") || "",
      accessor: "percent",
      id: "percent",
      width: "100px",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,

      Cell: ({ value }) => {
        return <PercentStatus value={value as number}/>;
      },
    },
    {
      Header: t("audits.list.score") || "",
      accessor: "score",
      id: "score",
      width: "100px",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,

      Cell: ({ row: { original }, value }) => {
        return `${getScoreString(
          original.score,
          original.maxScore,
          Math.round((original?.score * 100) / original.maxScore) || 0
        )}`;
      },
    },
    {
      Header: t("audits.list.planStatus") || "",
      accessor: "planStatus",

      width: "100px",
      minWidth: 100,
      maxWidth: 100,

      // Cell: ({ value }) => {
      //   return <PercentStatus value={value as number} />;
      // },
    },

    {
      Header: "",
      id: "actions",
      width: "118px",
      minWidth: 118,
      maxWidth: 118,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        const isNotResponsibleDealer = profile?.type === 'dealer' && profile?.id !== original.responsibleId;

        return (
          <StyledButtons>
            <Tooltip content={t("audits.list.copy") || ""} placement="bottom">
              <IconButton
                icon="copy"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => openCopyModal(original.id)}
              />
            </Tooltip>
            <Tooltip
              content={t("audits.list.eye-open") || ""}
              placement="bottom"
            >
              <Link to={
                !(isNotResponsibleDealer && original?.status !== "Новый") ?
                  (original?.status === "Закрытый"
                  ? `/audit-total/${original.id}`
                  : `/audit-list/fill/${original.id}`)
                : `/audit-list/info/${original.id}`
              }
              >
                <IconButton
                  icon="eye-open"
                  color="black"
                  size="l"
                  variant="tertiary"

                />
              </Link>
            </Tooltip>
            <Tooltip
              content={t("audits.list.deleteAudit") || ""}
              placement="bottom"
            >
              <Popconfirm
                title={t("audits.list.deleteConfirm") || ""}
                onConfirm={() => {
                  onDeleteClick && onDeleteClick(original.id);
                }}
                okText={t("common.yes") || ""}
                cancelText={t("common.no") || ""}
              >
                <IconButton
                  icon="delete"
                  color="black"
                  size="l"
                  variant="tertiary"
                />
              </Popconfirm>
            </Tooltip>
          </StyledButtons>
        );
      },
    },
  ];
};
