import React, { useEffect, useState } from "react";

import { Icon } from "@/packages/icon/Icon";
import {
  StyledAdd,
  StyledCellContent,
  StyledDataCell,
  StyledHead,
  StyledHeadCell,
  StyledNavigation,
  StyledNavigationCurve,
  StyledOrdinaryOpen,
  StyledSearchButton,
  StyledSortIcon,
  StyledTable,
  StyledTableDataCell,
  StyledTableRow,
  StyledTableRowAdd,
  WrappedTable,
} from "./NestedTable.styles";

import { Search } from "@/packages/formElements/inputs/search/Search";
import { IconButton } from "../icon-button/IconButton";
import { Paragraph } from "../paragraph/Paragraph";
import { TModelRecord } from "@/helpers/api/models/types";
import { TGenerationRecord } from "@/helpers/api/generations/types";
import { useTranslation } from "react-i18next";
import { DelimitedNumericArrayParam, useQueryParam } from "use-query-params";

export interface IDataItem {
  [key: string]: any;
}

export interface IHeaderItem extends IColumn {
  Header: JSX.Element | string;
  accessor: string;
  columns?: IHeaderItem[];
  id?: string;
  Cell?: React.FC<{ dataItem: any }>;
}

export interface IColumn {
  isSortable?: boolean;
  isSearchable?: boolean;
  isFixedWidth?: number;
  isOpening?: boolean;
}

export type SortOrder = null | "asc" | "desc";

export interface INestedTable {
  className?: string;
  headers: IHeaderItem[];
  data?: IDataItem[];
  variant?: "primary" | "secondary";
  isReadOnly?: boolean;
  onSort?: (accessor: string, order: SortOrder) => void;
  onSearch?: (key: string, value?: string) => void;
  onCreateModel?: () => void;
  onCreateGeneration?: (modelRecord: TModelRecord) => void;
  onCreateCompleteSetClick?: (modelRecord: TGenerationRecord) => void;
  onOpenModel?: (modelId: string, modelRecord: TModelRecord) => void;
  onOpenGeneration?: (
    generationId: string,
    generationRecord: TGenerationRecord
  ) => void;
}

const NestedTable = (props: INestedTable): JSX.Element => {
  const {
    className,
    headers,
    data,
    variant = "primary",
    isReadOnly = false,
    onSort,
    onSearch,
    onCreateModel,
    onCreateGeneration,
    onCreateCompleteSetClick: onCreateCompleteSet,
    onOpenModel,
  } = props;

  const { t } = useTranslation();

  const [sortOrders, setSortOrders] = useState<SortOrder[]>(
    headers.map(() => null)
  );
  useEffect(() => setSortOrders(headers.map(() => null)), []);

  const handleHeaderClick = (header: IHeaderItem & IColumn, index: number) => {
    if (header.isSortable && !(searchedField === header.accessor && searchedField)) {
      const sortOrder = sortOrders[index];
      let newSortOrder: SortOrder = null;
      if (!sortOrder) newSortOrder = "asc";
      if (sortOrder === "asc") newSortOrder = "desc";
      if (sortOrder === "desc") newSortOrder = null;
      const newSortOrders: SortOrder[] = headers.map(() => null);
      newSortOrders[index] = newSortOrder;
      setSortOrders(newSortOrders);
      if (onSort) {
        setRowOpen([]);
        onSort(header.accessor, newSortOrder)
      }
    }
  };

  const [searchedField, setSearchedFiled] = useState<string>();

  const [modelsQuery, setModelsQuery] = useQueryParam('model', DelimitedNumericArrayParam)

  const [rowOpen, setRowOpen] = useState<number[]>([]);
  const [openChild, setOpenChild] = useState<number[]>([]);

  useEffect(() => {
    if (!modelsQuery) {
      return;
    }
    const models = modelsQuery.filter(value => value !== null);
    //@ts-ignore
    setRowOpen(models);
  }, [])

  useEffect(() => {
    setModelsQuery(rowOpen);
  }, [rowOpen])

  useEffect(() => {
    if (!data || rowOpen.length === 0) {
      return
    }
    for (const row of rowOpen) {
      if (
        data[row]?.child[0]?.child === undefined &&
        onOpenModel
      ) {
        onOpenModel(data[row]?.id, data[row] as TModelRecord);
      }
    }
  }, [data]);

  const NestedChildren: React.FC<{
    childrenData: IDataItem;
    nestedIndex: number;
    isOrdinary?: boolean;
  }> = (props) => {
    const { childrenData, nestedIndex } = props;


    const handleOpenChild = (childIndex: number) => {
      setOpenChild((prev) =>
        prev.includes(childIndex)
          ? prev.filter((item) => item !== childIndex)
          : [...prev, childIndex]
      );
    };

    return (
      <>
        {childrenData?.map((data, index) => (
          <React.Fragment key={index}>
            <StyledTableRow isChild={true} isSecondChild={nestedIndex === 2}>
              {headers.map((headerItem, headerIndex) => {
                const isOpen = openChild.includes(index);
                return (
                  <>
                    <StyledTableDataCell
                      isSmall={true}
                      hideBorder={true}
                      isHidden={
                        nestedIndex === 2 &&
                        headerIndex === 1 &&
                        variant === "primary"
                      }
                      key={headerIndex}
                      colSpan={
                        nestedIndex === 2 &&
                        headerIndex === 2 &&
                        variant === "primary"
                          ? 2
                          : 1
                      }
                    >
                      <StyledDataCell
                        textML={
                          !headerIndex
                            ? variant === "primary"
                              ? 60 * nestedIndex
                              : 22 * nestedIndex
                            : 0
                        }
                      >
                        {variant === "primary" ? (
                          <>
                            {!headerIndex ? (
                              <>
                                {Array.from(Array(nestedIndex).keys()).map(
                                  (_, i) => (
                                    <StyledNavigation marginLeft={60 * i}/>
                                  )
                                )}
                                <StyledNavigationCurve
                                  marginLeft={nestedIndex !== 1 ? 60 : 0}
                                >
                                  <Icon name="curve" size={24} color="brand"/>
                                </StyledNavigationCurve>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {headerItem.isOpening && data?.child ? (
                          <>
                            {variant === "primary" ? (
                              <IconButton
                                onClick={() => handleOpenChild(index)}
                                icon={isOpen ? "arrow-up" : "arrow-down"}
                                size="s"
                                variant={isOpen ? "primary" : "secondary"}
                                color={isOpen ? "brand" : "black"}
                              >
                                {`${data[headerItem.accessor]} ${
                                  data.restylingName ? data.restylingName : ""
                                }`}
                              </IconButton>
                            ) : (
                              <StyledOrdinaryOpen>
                                {data[headerItem.accessor]}
                                <IconButton
                                  onClick={() => handleOpenChild(index)}
                                  icon={isOpen ? "arrow-up" : "arrow-down"}
                                  size="s"
                                  variant={isOpen ? "primary" : "secondary"}
                                  color={isOpen ? "brand" : "black"}
                                />
                              </StyledOrdinaryOpen>
                            )}
                          </>
                        ) : headerItem.Cell ? (
                          <headerItem.Cell dataItem={data}/>
                        ) : (
                          data[headerItem.accessor]
                        )}
                      </StyledDataCell>
                    </StyledTableDataCell>
                  </>
                );
              })}
            </StyledTableRow>

            {openChild.includes(index) ? (
              <>
                {(
                  <NestedChildren
                    childrenData={data.child}
                    nestedIndex={nestedIndex + 1}
                  />
                )}

                {variant === "primary" ? (
                  <StyledTableRow>
                    {nestedIndex === 1 ? (
                      <StyledTableRowAdd colSpan={headers.length}>
                        {Array.from(Array(nestedIndex + 1).keys()).map(
                          (_, i) => (
                            <StyledNavigation
                              marginLeft={60 * i}
                              isEnd={i === 1}
                            />
                          )
                        )}
                        <StyledAdd marginLeft={60}>
                          <IconButton
                            icon="plus"
                            color="brand"
                            variant="secondary"
                            size="s"
                            isDisabled={isReadOnly}
                            onClick={() =>
                              onCreateCompleteSet && onCreateCompleteSet(data)
                            }
                          >
                            <Paragraph
                              size={12}
                              color={isReadOnly ? "gray3" : "brand"}
                            >
                              {t("models.addNewModification") || ""}
                            </Paragraph>
                          </IconButton>
                        </StyledAdd>
                      </StyledTableRowAdd>
                    ) : (
                      <></>
                    )}
                  </StyledTableRow>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <WrappedTable className={className}>
      <StyledTable>
        <StyledHead isOrdinary={variant === "secondary"}>
          {headers.map((headerItem, headerIndex) => {
            const sortOrder = sortOrders[headerIndex];

            return (
              <StyledHeadCell
                key={headerIndex}
                isSortable={!!headerItem.isSortable}
                onClick={() => handleHeaderClick(headerItem, headerIndex)}
              >
                <StyledCellContent>
                  {searchedField === headerItem.accessor && searchedField ? (
                    <Search
                      onChange={(value) => {
                        onSearch && onSearch(searchedField, value);
                      }}
                      onClear={() => {
                        onSearch && onSearch(searchedField, "");
                        setSearchedFiled("");
                      }}
                    />
                  ) : (
                    <>
                      {headerItem.isSearchable ? (
                        <StyledSearchButton>
                          <IconButton
                            icon="search-16"
                            onClick={() =>
                              setSearchedFiled(headerItem.accessor)
                            }
                            size="m"
                            variant="secondary"
                            isSquared={true}
                            color="black"
                          />
                        </StyledSearchButton>
                      ) : (
                        <></>
                      )}
                      {
                        // @ts-ignore
                        t(headerItem.Header) || ""
                      }
                      {headerItem.isSortable && (
                        <StyledSortIcon>
                          <Icon
                            size={8}
                            name="chevron-sort-up-8"
                            color={sortOrder === "asc" ? "black" : "gray400"}
                          />
                          <Icon
                            size={8}
                            name="chevron-sort-down-8"
                            color={sortOrder === "desc" ? "black" : "gray400"}
                          />
                        </StyledSortIcon>
                      )}
                    </>
                  )}
                </StyledCellContent>
              </StyledHeadCell>
            );
          })}
        </StyledHead>
        {data?.map((dataItem, dataIndex) => {
          const isRowOpenIncludes = rowOpen.includes(dataIndex);
          return (
            <React.Fragment key={dataIndex}>
              <StyledTableRow>
                {headers.map((headerItem, headerIndex) => {
                  return (
                    <StyledTableDataCell
                      hideBorder={variant === "secondary"}
                      key={headerIndex}
                    >
                      <StyledDataCell>
                        {headerItem.isOpening ? (
                          <>
                            {variant === "primary" ? (
                              <IconButton
                                icon={
                                  isRowOpenIncludes
                                    ? "double-shevron-up"
                                    : "double-shevron-down"
                                }
                                size="s"
                                color={isRowOpenIncludes ? "brand" : "gray"}
                                variant="tertiary"
                                onClick={() => {
                                  if (onOpenModel && data[dataIndex]) {
                                    onOpenModel(
                                      data[dataIndex]?.id,
                                      data[dataIndex] as TModelRecord
                                    );
                                  }

                                  setRowOpen((prev) => {
                                    return prev.includes(dataIndex)
                                      ? prev.filter(
                                        (item) => item !== dataIndex
                                      )
                                      : [...prev, dataIndex];
                                  });
                                }}
                              >
                                {dataItem[headerItem.accessor]}
                              </IconButton>
                            ) : (
                              <StyledOrdinaryOpen>
                                <Paragraph size={18}>
                                  {dataItem[headerItem.accessor]}
                                </Paragraph>
                                <IconButton
                                  icon={
                                    isRowOpenIncludes
                                      ? "arrow-up"
                                      : "arrow-down"
                                  }
                                  size="s"
                                  color={isRowOpenIncludes ? "brand" : "black"}
                                  variant={
                                    isRowOpenIncludes ? "primary" : "secondary"
                                  }
                                  onClick={() =>
                                    setRowOpen((prev) =>
                                      prev.includes(dataIndex)
                                        ? prev.filter(
                                          (item) => item !== dataIndex
                                        )
                                        : [...prev, dataIndex]
                                    )
                                  }
                                />
                              </StyledOrdinaryOpen>
                            )}
                          </>
                        ) : headerItem.Cell ? (
                          <headerItem.Cell dataItem={dataItem}/>
                        ) : (
                          dataItem[headerItem.accessor]
                        )}
                      </StyledDataCell>
                    </StyledTableDataCell>
                  );
                })}
              </StyledTableRow>
              {isRowOpenIncludes ? (
                <>
                  <NestedChildren
                    childrenData={dataItem.child}
                    nestedIndex={1}
                  />
                  {variant === "primary" ? (
                    <StyledTableRowAdd
                      colSpan={headers.length}
                      hasBorder={true}
                    >
                      <StyledNavigation marginLeft={0} isEnd={true}/>

                      <StyledAdd marginLeft={0}>
                        <IconButton
                          icon="plus"
                          color="brand"
                          variant="secondary"
                          size="s"
                          isDisabled={isReadOnly}
                          onClick={() =>
                            onCreateGeneration &&
                            onCreateGeneration(dataItem as TModelRecord)
                          }
                        >
                          <Paragraph
                            size={12}
                            color={isReadOnly ? "gray3" : "brand"}
                          >
                            {t("models.addNewGeneration") || ""}
                          </Paragraph>
                        </IconButton>
                      </StyledAdd>
                    </StyledTableRowAdd>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </React.Fragment>
          );
        })}
        {variant === "primary" ? (
          <StyledTableRow>
            <StyledTableRowAdd colSpan={headers.length} isBottom={true}>
              <IconButton
                icon="plus"
                color="brand"
                variant="secondary"
                size="s"
                isDisabled={isReadOnly}
                onClick={onCreateModel}
              >
                <Paragraph size={12} color={isReadOnly ? "gray3" : "brand"}>
                  {t("models.addNewModel") || ""}
                </Paragraph>
              </IconButton>
            </StyledTableRowAdd>
          </StyledTableRow>
        ) : (
          <></>
        )}
      </StyledTable>
    </WrappedTable>
  );
};
export { NestedTable };
