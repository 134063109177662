import React, { useMemo } from 'react';

import { Search } from '@/packages/formElements/inputs/search/Search';
import { Title } from '@/packages/title/Title';

import { StyledAutoParkDescWrapper, StyledAutoParkDesc, StyledSelect } from './index.styles';
import { TGetFiltersVehicleResponse } from '@/helpers/api/vehicles/types';
import { useProfile } from '@/hooks/contexts/useProfile';
import { isAdminUserBySlug } from '@/helpers/utils';

export interface IAutoParkDesc {
    title: string;
    description: string;
    searchValue?: string;
    filtersData?: TGetFiltersVehicleResponse;
    handleSearch: (value?: string) => void;
    setRequestParams: any;
}

export const AutoParkDesc: React.FC<IAutoParkDesc> = (props) => {
    const { title, filtersData, setRequestParams, searchValue, handleSearch } = props;

    const preparedFilterList = useMemo(
        () =>
            filtersData?.groups.map((item) => ({
                label: item.name,
                value: item.id,
            })) || [],
        [ filtersData ],
    );

    const {
        state: { profile },
    } = useProfile();

    const isProfileAdmin =
        isAdminUserBySlug(profile?.role?.slug) ||
        profile?.role?.slug === 'kia-admin';

    return (
        <StyledAutoParkDescWrapper>
            <StyledAutoParkDesc>
                <Title level={4} weight={300}>
                    {title}
                </Title>
            </StyledAutoParkDesc>
            {
                isProfileAdmin &&
                <StyledSelect
                    allowClear
                    label={'Парк автомобилей'}
                    placeholder={'Выберите парк автомобилей'}
                    options={preparedFilterList}
                    onChange={(value) => {
                        setRequestParams((prev) => ({
                            //@ts-ignore
                            ...prev,
                            //@ts-ignore
                            'filter[groupId]': value,
                        }));
                    }}
                />
            }
            <Search value={searchValue} onChange={(value) => handleSearch(value)}/>
        </StyledAutoParkDescWrapper>
    );
};
