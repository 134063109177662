import { LogsTableAdapter } from '@/adapters/logs/LogsTableAdapter';
import { useGetEventLog, useRollbackEventLog } from '@/helpers/api/eventLogs/hooks';
import { usePagination } from '@/hooks/usePagination';
import { useNotification } from '@/hooks/useNotification';
import React, { useEffect, useState } from 'react';

import { StyledModelsLogsTab } from './index.styles';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { ITab } from '@/packages/tabs/Tabs';
import { useDebounce } from 'use-debounce';
import { IHeaderItem } from '@/packages/tableSimple/TableSimple';
import { TEventLogRecord } from '@/helpers/api/eventLogs/types';
import { StyledUserNameWrapper } from '@/components/UserInfoModal/index.styled';
import { Paragraph } from '@/packages/paragraph/Paragraph';
import { getLogMessage } from '@/components/logs/LogsTable/helpers';
import { useUserInfoModel } from '@/components/UserInfoModal/provider';
import { Button } from '@/packages/button/Button';

interface ModelsLogsTabType {
    isTabChanged: ITab | undefined;
}

export const ModelsLogsTab: React.FC<ModelsLogsTabType> = (props) => {
    const { isTabChanged } = props;

    const { createNotificationSuccess, createNotificationError } =
        useNotification();
    const { t } = useTranslation();
    const [ searchValue, setSearchValue ] = useState<string>('');
    const [ debouncedSearchValue ] = useDebounce(searchValue, 800);


    const [ requestParams, setRequestParams ] = useState<any>({
        sortBy: null,
        orderBy: null,
    });
    const { paginationParams, setPage } = usePagination();
    const {
        data: modelsLogs,
        isLoading,
        refetch,
    } = useGetEventLog({
        ...paginationParams,
        search: debouncedSearchValue,
        ...requestParams,
    });
    const { mutateAsync: rollbackEventLogAsync } = useRollbackEventLog();

    const handleSuccessRestore = () => {
        refetch();
        createNotificationSuccess(
            t('models.notifications.createNotificationSuccess') || '',
        );
    };

    const handeRestoreError = (error: AxiosError) => {
        createNotificationError(error.message);
    };

    useEffect(() => {
        refetch();
    }, [ isTabChanged ]);

    const { dispatch } = useUserInfoModel();

    const headers: IHeaderItem<TEventLogRecord>[] = [
        {
            Header: t('models.logsModel.date') || '',
            accessor: 'date',
            isSortable: true,
            maxWidth: 100,
            Cell: ({ row }) => new Date(row?.original?.date || '').toLocaleString(),
        },
        {
            Header: t('models.logsModel.account') || '',
            accessor: 'account',
            maxWidth: 100,

        },
        {
            Header: t('models.logsModel.user') || '',
            accessor: 'user',
            maxWidth: 100,

            Cell: ({ row }) => {
                return (
                    <StyledUserNameWrapper onClick={() => dispatch({ userId: row.original.user?.id ?? '' })}>
                        {`${row.original.user?.firstName} ${row.original.user?.lastName}`}
                    </StyledUserNameWrapper>
                );
            },
        },
        {
            Header: t('models.logsModel.change') || '',
            accessor: 'action',
            Cell: ({ row }) => {
                return (
                    <Paragraph size={12} weight={300}>
                        {getLogMessage(
                            row.original.action,
                            row.original.entity,
                            row.original,
                        )}
                    </Paragraph>
                );
            },
        },
        {
            Header: '',
            id: 'rollback',
            maxWidth: 100,
            Cell: ({ row }) => {
                const onClickRollbackHandler = () => {
                    row.original.id && rollbackEventLogAsync(
                        row.original.id,
                        {
                            onSuccess: handleSuccessRestore,
                            onError: (error) => handeRestoreError(error as AxiosError<{ message: string }>),
                        },
                    );
                };
                return (
                    <Button
                        color='brand'
                        size='small'
                        onClick={onClickRollbackHandler}
                        isDisabled={row.original.restored}
                    >
                        Восстановить
                    </ Button>
                );
            },
        },
    ];

    return (
        <StyledModelsLogsTab>
            <LogsTableAdapter
                isLoading={isLoading}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                //@ts-ignore
                data={modelsLogs?.data?.data || []}
                headers={headers}
                hasPagination={!!modelsLogs?.data.meta?.pageCount}
                setPage={setPage}
                setRequestParams={setRequestParams}
                paginationParams={{
                    page: paginationParams.page,
                    limit: modelsLogs?.data.meta?.pageCount || 10,
                }}
            />
        </StyledModelsLogsTab>
    );
};
