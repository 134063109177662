import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { api } from "../axiosInstance";
import {
  TConfirmChangeRoleRequest,
  TConfirmChangeRoleResponse,
  TConfirmDealerRegistrationByDealerRequest,
  TConfirmDealerRegistrationByDealerResponse,
  TConfirmDealerRegistrationByKiaRequest,
  TConfirmDealerRegistrationByKiaResponse,
  TExportUsersRequest,
  TExportUsersResponse,
  TGetDealerEmployeesRequest,
  TGetDealerEmployeesResponse,
  TGetUserByIdRequest,
  TGetUserByIdResponse,
  TGetUserListRequest,
  TGetUserListResponse,
  TGetUsersPreviewRequest,
  TGetUsersPreviewResponse,
  TPatchUserActivityRequest,
  TPatchUserActivityResponse,
  TRejectChangeRoleRequest,
  TRejectChangeRoleResponse,
  TRejectDealerRegistrationByDealerRequest,
  TRejectDealerRegistrationByDealerResponse,
  TRejectDealerRegistrationByKiaRequest,
  TRejectDealerRegistrationByKiaResponse,
  TUserDeleteRequest,
  TUserDeleteResponse,
  TPatchUserRequest,
  TPatchUserUpdateResponse,
} from "./types";

export const useGetUserList = (request: TGetUserListRequest) => {
  return useQuery(["users", "list", request], () => {
    return api.get<TGetUserListResponse>("/users", {
      params: request,
    });
  });
};

export const useGetDealerEmployeesList = (
  request: TGetDealerEmployeesRequest
) => {
  return useQuery(["employees", "list", request], () => {
    return api.get<TGetDealerEmployeesResponse>("/users/dealer-employees", {
      params: request,
    });
  });
};

export const useGetUsersPreview = (request: TGetUsersPreviewRequest) => {
  return useQuery(["users", "preview"], () => {
    return api.get<TGetUsersPreviewResponse>(`/users/preview`, {
      params: request,
    });
  });
};

export const useGetUserById = (request: TGetUserByIdRequest) => {
  return useQuery(["users", "detail", request.id], () => {
    return api.get<TGetUserByIdResponse>(`/users/${request.id}`,);
  }, { enabled: !!request.id });
};

export const useEditUser = () => {
  return useMutation(
    ({
       request,
       onSuccess,
       onError,
     }: {
      request: TPatchUserRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchUserUpdateResponse>(
        `/users/${request.id}`,
        request.patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useEditUserActivity = () => {
  return useMutation(
    ({
       request,
       onSuccess,
       onError,
     }: {
      request: TPatchUserActivityRequest;
      onSuccess: () => void;
      onError: (error: AxiosError<{ message: string }>) => void;
    }) => {
      return api.patch<TPatchUserActivityResponse>(
        `/users/${request.id}/activity`,
        request.patch
      );
    },
    {
      onSuccess: (resp, { onSuccess }) => {
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: (response, { onError }) => {
        if (onError) {
          onError(response as AxiosError<{ message: string }>);
        }
      },
    }
  );
};

export const useDeleteUser = ({
                                onSuccess,
                                onError,
                              }: {
  onSuccess?: () => void;
  onError?: () => void;
} = {}) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TUserDeleteRequest) => {
      return api.delete<TUserDeleteResponse>(`/users/${data.id}`);
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(["users", "detail", variables.id]);
        queryClient.invalidateQueries(["users", "list"]);
        onSuccess && onSuccess();
      },
      onError: () => {
        onError && onError();
      },
    }
  );
};

export const useExportUsers = () => {
  return useMutation((request: TExportUsersRequest) => {
    return api.get<TExportUsersResponse>(`/users/export`, {
      params: request,
      responseType: "blob",
    });
  });
};

export const useConfirmDealerRegistrationByDealer = () => {
  return useMutation((request: TConfirmDealerRegistrationByDealerRequest) =>
    api.patch<TConfirmDealerRegistrationByDealerResponse>(
      `/users/${request.id}/confirm-dealer-registration-by-dealer`,
      {
        params: request,
      }
    )
  );
};

export const useRejectDealerRegistrationByDealer = () => {
  return useMutation((request: TRejectDealerRegistrationByDealerRequest) =>
    api.patch<TRejectDealerRegistrationByDealerResponse>(
      `/users/${request.id}/reject-dealer-registration-by-dealer`,
      {
        params: request,
      }
    )
  );
};

export const useConfirmDealerRegistrationByKia = () => {
  return useMutation((request: TConfirmDealerRegistrationByKiaRequest) =>
    api.patch<TConfirmDealerRegistrationByKiaResponse>(
      `/users/${request.id}/confirm-dealer-registration-by-kia`,
      {
        params: request,
      }
    )
  );
};

export const useRejectDealerRegistrationByKia = () => {
  return useMutation((request: TRejectDealerRegistrationByKiaRequest) =>
    api.patch<TRejectDealerRegistrationByKiaResponse>(
      `/users/${request.id}/reject-dealer-registration-by-kia`,
      {
        params: request,
      }
    )
  );
};

export const useConfirmRoleChange = () => {
  return useMutation((request: TConfirmChangeRoleRequest) =>
    api.patch<TConfirmChangeRoleResponse>(
      `/users/${request.id}/confirm-role-change`
    )
  );
};
export const useRejectRoleChange = () => {
  return useMutation((request: TRejectChangeRoleRequest) =>
    api.patch<TRejectChangeRoleResponse>(
      `/users/${request.id}/reject-role-change`
    )
  );
};
