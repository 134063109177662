import { TWarrantyAuditListData } from "@/helpers/api/warranty-audit/audit-list/types";
import { IconButton } from "@/packages/icon-button/IconButton";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { IHeaderItem } from "@/packages/tableSimple/TableSimple";
import { Popconfirm } from "antd";

import { StyledButtons } from "./index.styles";
import { t } from "i18next";
import { formatDate } from "@/helpers/formatDate";
import { Tooltip } from "@/packages/Tooltip/Tooltip";
import { Dispatch } from "@/components/UserInfoModal/provider";
import { StyledUserNameWrapper } from "@/components/UserInfoModal/index.styled";

export const getDefaultAuditTableHeaders = <TRecord extends object>(options: {
  onDeleteClick?: (id: number) => void;
  openCopyModal?: (id: number) => void;
  onViewClick: (path: string) => void;
  onArchive?: (id: number) => void;
  openUserModal: Dispatch;
}): IHeaderItem<TWarrantyAuditListData>[] => {
  const { onDeleteClick, openCopyModal, onViewClick, onArchive, openUserModal } = options;
  return [
    {
      Header: "ID",
      accessor: "id",
      minWidth: 78,
      maxWidth: 78,
    },
    {
      Header: t("audits.list.status") || "",
      accessor: "status",
      id: "status",
      isSortable: true,
      minWidth: 100,
      maxWidth: 100,
    },
    {
      Header: t("audits.list.titleTable") || "",
      accessor: "title",
      id: "title",
      isSortable: true,
      minWidth: 100,
      maxWidth: 120,
    },
    {
      Header: t("warrantyAudits.list.visitNumber") || "",
      accessor: "visitNumber",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,
    },
    {
      Header: t("audits.list.createdAt") || "",
      accessor: "createdAt",
      id: "createdAt",
      isSortable: true,
      minWidth: 125,
      maxWidth: 125,
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={300}>
            {value ? formatDate(new Date(value), "dateOnly") : "-"}
          </Paragraph>
        );
      },
    },
    {
      Header: t("warrantyAudits.list.endDate") || "",
      accessor: "completedAt",
      id: "completedAt",
      isSortable: true,
      minWidth: 125,
      maxWidth: 125,
      Cell: ({ row: { original }, value }) => {
        return (
          <Paragraph size={12} weight={300}>
            {value ? formatDate(new Date(value), "dateOnly") : "-"}
          </Paragraph>
        );
      },
    },
    {
      Header: t("audits.list.auditorId") || "",
      accessor: "auditor",
      id: "auditor",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original.auditor.id })}>
            {`${original?.auditor?.firstName || ""} ${
              original?.auditor?.lastName || ""
            }`}
          </StyledUserNameWrapper>
        );
      },
    },
    {
      Header: t("audits.list.dealer") || "",
      accessor: "dealer.merchantName",
      id: "dealer",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,
    },
    {
      Header: t("audits.list.responsible") || "",
      accessor: "responsible",
      id: "responsible",
      minWidth: 100,
      maxWidth: 120,
      isSortable: true,
      Cell: ({ row: { original }, value }) => {
        return (
          <StyledUserNameWrapper onClick={() => openUserModal({ userId: original.responsible.id })}>
            {`${original?.responsible?.firstName || ""} ${
              original?.responsible?.lastName || ""
            }`}
          </StyledUserNameWrapper>
        );
      },
    },
    {
      Header: t("warrantyAudits.list.stage") || "",
      accessor: "stage",
      id: "stage",
      minWidth: 100,
      maxWidth: 100,
      isSortable: true,
    },
    {
      Header: "",
      id: "actions",
      minWidth: 130,
      maxWidth: 130,
      sticky: "right",
      Cell: ({ row: { original } }) => {
        return (
          <StyledButtons>
            <Tooltip content={"Копировать"} placement="bottom">
              <IconButton
                icon="copy"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => openCopyModal && openCopyModal(original.id)}
              />
            </Tooltip>
            <Tooltip content={"Перейти"} placement="bottom">
              <IconButton
                icon="eye-open"
                color="black"
                size="l"
                variant="tertiary"
                onClick={() => {
                  onViewClick(`/warranty-audit/fill/${original.id}`);
                }}
              />
            </Tooltip>
            {original.status === "Закрытый" ||
            original.status === "В архиве" ? (
              <Tooltip
                content={
                  original.status === "В архиве"
                    ? t("notifications.unarchive") || ""
                    : t("notifications.archive") || ""
                }
                placement="bottom">
                <IconButton
                  icon="archive"
                  color="black"
                  size="l"
                  variant="tertiary"
                  onClick={() => onArchive && onArchive(original.id)}
                />
              </Tooltip>
            ) : (
              <Tooltip content={"Удалить"} placement="bottom">
                <Popconfirm
                  title={t("audits.list.deleteConfirm") || ""}
                  onConfirm={() => {
                    onDeleteClick && onDeleteClick(original.id);
                  }}
                  okText={t("common.yes") || ""}
                  cancelText={t("common.no") || ""}
                >
                  <IconButton
                    icon="delete"
                    color="black"
                    size="l"
                    variant="tertiary"
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </StyledButtons>
        );
      },
    },
  ];
};
