import { useGetProfile } from "@/helpers/api/profile/hooks";
import { FormDatepickerField } from "@/packages/formElements/formFields/FormDatepickerField";
import { FormInputField } from "@/packages/formElements/formFields/FormInputField";
import { Title } from "@/packages/title/Title";
import { Col, Row, Space } from "antd";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { TrasnportComplaintsFields } from "../TransportComplaintsFields";
import { TransportComNameFields } from "../TransportComplaintsFields/types";
import {
  getDefaultValues,
  ITransportComFormCreateValues,
  TOnSubmitTransportFormCreate,
  useScrollToTcTop,
  useTransportComFormCreate,
} from "./hooks";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParams } from "use-query-params";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "@/components/TransportComplaints/TransportComplaintsDetail/index.styles";
import { useNotification } from "@/hooks/useNotification";
import { useGetFilteredDealerList } from "@/helpers/api/global-filters/hooks";

export interface ITransportComplaintsFormCreateProps {
  onSubmitChange: TOnSubmitTransportFormCreate;
  record?: ITransportComFormCreateValues;
}

const _TransportComplaintsFormCreate: React.FC<
  ITransportComplaintsFormCreateProps
> = (props) => {
  const [prefillQueryParams] = useQueryParams({
    p: StringParam, // sap код диллера
    d: StringParam, // дата
    v: StringParam, // VIN
    c: StringParam, // warranty code код случай
  });
  const { data: dealerBySapCode } =
    useGetFilteredDealerList({ q: prefillQueryParams.p ?? undefined, limit: 0 });

  const { t } = useTranslation();
  const { onSubmitChange } = props;
  const profile = useGetProfile();
  const isKiaProfileType = profile.data?.data.type === "kia";
  const isDealerProfileType = profile?.data?.data.type === "dealer";
  const isDistributorProfileType = profile?.data?.data?.role?.slug === "cis-distributor";

  const navigate = useNavigate();
  const { createNotificationError } = useNotification();


  const { form, submit, reset } = useTransportComFormCreate(
    onSubmitChange,
    prefillQueryParams.p ? dealerBySapCode?.data.data[0] : undefined,
    prefillQueryParams,
  );

  useScrollToTcTop(form);

  useEffect(() => {
    if (prefillQueryParams.p) {
      reset(getDefaultValues(
        prefillQueryParams,
        dealerBySapCode?.data.data[0])
      )
    }
  }, [dealerBySapCode])

  const placeAcceptence =
    !isKiaProfileType && profile?.data?.data.dealer?.merchantName;

  const handleCancelClick = () => {
    navigate('/transport-claims');
  };

  useEffect(() => {
    if (prefillQueryParams.d) {
      const date = Date.parse(prefillQueryParams.d)
      if (isNaN(date)) {
        navigate('/transport-claims');
        createNotificationError('Неверный формат даты');
      }
    }
  }, [prefillQueryParams])

  return (
    <>
      {placeAcceptence && (
        <Title level={2}>Место приемки : {placeAcceptence}</Title>
      )}
      <FormProvider {...form}>
        <Row gutter={16}>
          <Col span={8}>
            <FormInputField
              name={TransportComNameFields.VIN}
              label={t("complaints.VIN*") || ""}
            />
          </Col>
          <Col span={8}>
            <FormInputField
              name={TransportComNameFields.CODE_CASE}
              label={t("complaints.codeCase*") || ""}
            />
          </Col>
          <Col span={8}>
            <FormDatepickerField
              name={TransportComNameFields.DATE_ACCEPTENCE}
              label={t("complaints.dateAcceptenceStart*") || ""}
            />
          </Col>
        </Row>
        <TrasnportComplaintsFields
          form={form}
          mode="create"
          isDisabled={false}
          isDealerProfileType={isDealerProfileType}
          isDistributorProfileType={isDistributorProfileType}
        />
      </FormProvider>
      <Space size={16}>
        <StyledButton onClick={handleCancelClick}>
          {t("common.back") || ""}
        </StyledButton>
        <StyledButton theme="primary" onClick={submit}>
          {t("complaints.add") || ""}
        </StyledButton>
      </Space>
    </>
  );
};
export const TransportComplaintsFormCreate = React.memo(
  _TransportComplaintsFormCreate
);
