import { colors } from "@/helpers";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DashboardLayout } from "@/layouts/DashboardLayout";
import { Button } from "@/packages/button/Button";
import { SortOrder } from "@/packages/nestedTable/NestedTable";
import { ITab, Tabs } from "@/packages/tabs/Tabs";

import { AutoParkDesc } from "@/components/AutoPark/AutoParkDesc";
import { AutoParkTable } from "@/components/AutoPark/AutoParkTable";

import {
  useGetVehicleParkList,
  usePatchFromArchiveVehicle,
  usePatchToArchiveVehicle, useGetVehicleFiltersList,
} from '@/helpers/api/vehicles/hooks';
import { useActiveTab } from "@/hooks/useActiveTab";
import { useRequestParams } from "@/hooks/useRequestParams";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { useTranslation } from "react-i18next";
import { useNotification } from "@/hooks/useNotification";

const archive = "archive";
const tabs: ITab[] = [
  { name: "park.autoInfo", slug: "info" },
  { name: "park.carArchived", slug: archive },
];

export const StyledAutoParkWrapper = styled.div`
  background-color: ${colors.white};
`;

export const AutoPark: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const { activeTab, setActiveTab } = useActiveTab(tabs);
  const [activeTabSlug, _] = useQueryParam("activeTab", StringParam);

  const { createNotificationError } = useNotification();

  const [requestParams, setRequestParams] = useRequestParams({
    take: {
      value: 1000,
      type: "page",
    },
    skip: {
      value: 0,
      type: "page",
    },
    name: {
      value: "",
      type: "sort",
    },
    type: {
      value: "",
      type: "sort",
    },
    "filter[archived]": activeTabSlug === archive,
    "filter[vehicle]": "",
    "filter[groupId]": undefined,
  });
  const {
    data: filterList,
  } = useGetVehicleFiltersList();

  const {
    data: response,
    isLoading: isAutoParkListLoading,
    refetch: refetchUseGetVehiclePark,
  } =
    //@ts-ignore
    useGetVehicleParkList(requestParams);
  const {
    mutateAsync: patchToArchiveAsync,
  } = usePatchToArchiveVehicle();
  const {
    mutateAsync: patchFromArchiveAsync,
    error: archivePatchError,
  } = usePatchFromArchiveVehicle();

  const onToFromHandler = (dataId: string) => {
    activeTabSlug !== archive
      ? patchToArchiveAsync({ id: dataId })
      : patchFromArchiveAsync({ id: dataId });
  };

  const onEditHandler = (dataId: string) => {
    navigate(`/autopark/${dataId}`);
  };

  const handleSort = (accessor: string, order: SortOrder) => {
    // @ts-ignore
    setRequestParams((prev) => ({
      ...prev,
      "sort[value]": order ? order.toUpperCase() : "ASC",
      "sort[field]": accessor,
    }));
  };

  const handleSearch = (value?: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    //@ts-ignore
    setRequestParams((prev) => ({
      //@ts-ignore
      ...prev,
      //@ts-ignore
      "filter[vehicle]": searchValue || "",
    }));
  }, [searchValue]);

  useEffect(() => {
    if (activeTabSlug) {
      const tab = tabs.find((el) => el.slug === activeTabSlug);
      if (tab) {
        //@ts-ignore
        setRequestParams((prev) => ({
          //@ts-ignore
          ...prev,
          //@ts-ignore
          "filter[archived]": activeTabSlug === archive,
        }));
      }
    }
  }, [activeTabSlug]);

  useEffect(() => {
    if (
      // @ts-ignore
      archivePatchError?.response?.data?.message === "Expired CASCO or OSAGO"
    ) {
      createNotificationError(t("park.expiredCASCO&OSAGO") || "");
    }
  }, [archivePatchError]);

  useEffect(() => {
    refetchUseGetVehiclePark();
  }, []);

  return (
    <DashboardLayout
      title={t("park.subTitle_2") || ""}
      headerRight={
        <Button
          onClick={() => navigate("/autopark/create")}
          color="brand"
          size="middle"
          theme="primary"
        >
          {t("park.addNewCar") || ""}
        </Button>
      }
    >
      <Tabs
        type="card"
        tabs={tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
      />
      <StyledAutoParkWrapper>
        <AutoParkDesc
          searchValue={searchValue}
          handleSearch={handleSearch}
          filtersData={filterList?.data}
          title={
            activeTabSlug === archive
              ? t("park.carArchived") || ""
              : t("park.carTest") || ""
          }
          setRequestParams={setRequestParams}
          description={
            activeTabSlug === archive
              ? t("park.allAuto") || ""
              : t("park.allAuto_2") || ""
          }
        />
        {response?.data.data ? (
          <AutoParkTable
            onSort={handleSort}
            onEditClick={onEditHandler}
            onToFromClick={onToFromHandler}
            data={response.data?.data || []}
            isLoading={isAutoParkListLoading}
            isArchiveTabActive={activeTabSlug === archive}
          />
        ) : (
          <></>
        )}
      </StyledAutoParkWrapper>
    </DashboardLayout>
  );
};
