import React, { useEffect, useState } from "react";

import { ModelsExportDictTab } from "@/components/Models/ModelsExportDictTab";
import { ModelsListFooter } from "@/components/Models/ModelsListFooter";
import { IModelsListTab, ModelsListTab, } from "@/components/Models/ModelsListTab";
import { DashboardLayout } from "@/layouts/DashboardLayout";
import { ITab, Tabs } from "@/packages/tabs/Tabs";

import { ModelsLogsTab } from "@/components/Models/ModelsLogsTab";
import { useActiveTab } from "@/hooks/useActiveTab";
import {
  useCreateModel,
  useExportModels,
  useGetModelList,
  usePatchModel,
  useUpdateModelStatus,
} from "@/helpers/api/models/hooks";
import { useNotification } from "@/hooks/useNotification";
import { TOnSubmitCreateModelFormCallback } from "@/components/Models/ModelsListTab/ModelCreateModal/hooks";
import { TOnSubmitEditModelFormCallback } from "@/components/Models/ModelsListTab/ModelEditModal/hooks";
import { TModelRecord } from "@/helpers/api/models/types";
import { useCreateGeneration, usePatchGeneration, useUpdateGenerationStatus, } from "@/helpers/api/generations/hooks";
import { useCreateEquipment, useDeleteEquipment, usePatchEquipment, } from "@/helpers/api/modifications/hooks";
import { usePagination } from "@/hooks/usePagination";
import { TGenerationRecord } from "@/helpers/api/generations/types";
import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { Placeholder } from "@/packages/placeholder/Placeholder";
import { SortOrder } from "@/packages/nestedTable/NestedTable";
import { StyledPagination } from "@/components/Models/ModelsListTab/index.styles";
import { handleFileLoad } from "@/helpers/utils";
import { useTranslation } from "react-i18next";
import { StringParam, useQueryParam } from "use-query-params";
import { Loader } from "@/packages/loader/Loader";
import { useProfile, ContextProfile } from '@/hooks/contexts/useProfile';
import { TRANSPORT_CLAIMS_ROUTE } from "@/constants/routes";
import { useNavigate } from "react-router-dom";

export const Models: React.FC = () => {
  const { createNotificationSuccess, createNotificationError } =
    useNotification();

  const { t } = useTranslation();
  const [searchModel, setSearchModel] = useState("");
  const [requestParams, setRequestParams] = useState<any>({
    sortBy: null,
    orderBy: null,
  });
  const [pageQuery, setPageQuery] = useQueryParam('page', StringParam)
  const { setPage, paginationParams } = usePagination();

  useEffect(() => {
    const numberPage = Number(pageQuery);
    if (numberPage && !isNaN(numberPage)) {
      setPage(numberPage)
    }
  }, [])

  useEffect(() => {
    setPageQuery(paginationParams.page?.toString())
  }, [paginationParams.page])

  const {
    data: getModelListResponse,
    refetch: refetchModelList,
    isLoading: isLoadingModelList,
    isRefetching: isRefetchingModelList,
    dataUpdatedAt: dataModelListUpdatedAt,
  } = useGetModelList({
    ...paginationParams,
    ...requestParams,
    name: searchModel,
  });

  const {
    state: { profile },
  } = useProfile();
  const navigate = useNavigate();

  const isEllada = profile?.role?.slug === 'ellada';

  useEffect(() => {
    if (isEllada) {
      navigate(TRANSPORT_CLAIMS_ROUTE);
    }
  }, [isEllada])

  const [modelList, setModelList] = useState<TModelRecord[] | []>([]);
  const [isTabChanged, setIsTabChanged] = useState<ITab>();

  const { mutateAsync: createModelAsync } = useCreateModel();
  const { mutateAsync: patchModelAsync } = usePatchModel();
  const { mutateAsync: updateModelStatusAsync } = useUpdateModelStatus();

  const { mutateAsync: createGenerationAsync } = useCreateGeneration();
  const { mutateAsync: patchGenerationAsync } = usePatchGeneration();
  const { mutateAsync: updateGenerationStatusAsync } =
    useUpdateGenerationStatus();

  const { mutateAsync: createEquipmentAsync } = useCreateEquipment();
  const { mutateAsync: patchEquipmentAsync } = usePatchEquipment();
  const { mutateAsync: deleteEquipmentAsync } = useDeleteEquipment();

  const { mutateAsync: exportModels } = useExportModels();

  useEffect(() => {
    if (getModelListResponse?.data?.data) {
      setModelList(getModelListResponse?.data?.data);
    }
  }, [getModelListResponse]);

  const handleSort = (accessor: string, order: SortOrder) => {
    setRequestParams((prev) => ({
      ...prev,
      orderBy: order ? order.toUpperCase() : null,
      sortBy: order ? accessor : null,
    }));
  };

  const onCreateModelHandler: TOnSubmitCreateModelFormCallback = async (
    data
  ) => {
    try {
      await createModelAsync(data);
      createNotificationSuccess(t("models.notifications.successAdded") || "");
    } catch (e) {
      createNotificationError(t("models.notifications.errorAdded") || "");
      throw e;
    }
  };

  const onEditModelHandler: TOnSubmitEditModelFormCallback = async (data) => {
    try {
      await patchModelAsync(data);
      createNotificationSuccess(t("models.notifications.successChanged") || "");
    } catch (e) {
      createNotificationError(t("models.notifications.errorChanged") || "");
      throw e;
    }
  };

  const onModelActiveChange = async ({
                                       id,
                                       isActive,
                                     }: {
    id: string;
    isActive: boolean;
  }) => {
    try {
      await updateModelStatusAsync({ id, data: { isActive } });
      createNotificationSuccess(t("models.notifications.successStatus") || "");
    } catch (e) {
      createNotificationError(t("models.notifications.errorStatus") || "");
      throw e;
    }
  };

  const onEditGenerationHandler: IModelsListTab["onEditGeneration"] = async ({
                                                                               patch,
                                                                               record,
                                                                             }) => {
    try {
      // @ts-ignore TODO: DELETE
      await patchGenerationAsync({ id: record.id, patch });
      createNotificationSuccess(
        t("models.notifications.successGenerationChanged") || ""
      );
    } catch (e) {
      createNotificationError(
        t("models.notifications.errorGenerationChanged") || ""
      );
      throw e;
    }
  };

  const onGenerationActiveChange = async ({
                                            id,
                                            isActive,
                                          }: {
    id: string;
    isActive: boolean;
  }) => {
    try {
      await updateGenerationStatusAsync({ id, data: { isActive } });
      createNotificationSuccess(
        t("models.notifications.successGenerationStatus") || ""
      );
    } catch (e) {
      createNotificationError(
        t("models.notifications.errorGenerationStatus") || ""
      );
      throw e;
    }
  };

  const onCreateGenerationHandler: IModelsListTab["onCreateGeneration"] =
    async (data) => {
      try {
        await createGenerationAsync(data);
        createNotificationSuccess(
          t("models.notifications.successGenerationAdded") || ""
        );
      } catch (e) {
        createNotificationError(
          t("models.notifications.errorGenerationAdded") || ""
        );
        throw e;
      }
    };

  const onCreateCompleteSetHandler: IModelsListTab["onCreateCompleteSet"] =
    async (data) => {
      try {
        await createEquipmentAsync(data);
        createNotificationSuccess(
          t("models.notifications.successEquipmentAdded") || ""
        );
      } catch (e) {
        createNotificationError(
          t("models.notifications.errorEquipmentAdded") || ""
        );
        throw e;
      }
    };

  const onEditCompleteSetHandler: IModelsListTab["onEditCompleteSet"] = async (
    data
  ) => {
    try {
      await patchEquipmentAsync({ id: data.record.id, patch: data.patch });
      createNotificationSuccess(
        t("models.notifications.successEquipmentChanged") || ""
      );
    } catch (e) {
      createNotificationError(
        t("models.notifications.errorEquipmentChanged") || ""
      );
      throw e;
    }
  };

  const onDeleteCompleteSetHandler: IModelsListTab["onDeleteCompleteSet"] =
    async (data) => {
      try {
        await deleteEquipmentAsync({ id: data.id });
        createNotificationSuccess(
          t("models.notifications.successEquipmentDeleted") || ""
        );
      } catch (e) {
        createNotificationError(
          t("models.notifications.errorEquipmentDeleted") || ""
        );
        throw e;
      }
    };

  const onOpenGenerationHandler = async (
    generationId: string,
    generationRecord: TGenerationRecord
  ) => {
  };

  const handleExportModels = () => {
    exportModels(
      {},
      {
        onSuccess: (response) => {
          handleFileLoad(response.data as Blob, "models.xlsx");
        },
      }
    );
  };



  const isDealerType = profile?.type === 'dealer';

  const tabs: ITab[] = [
    {
      name: t("models.title") || "",
      slug: "models",
      content: (
        <>
          <ModelsListTab
            modelListData={modelList}
            onCreateModel={onCreateModelHandler}
            onEditModel={onEditModelHandler}
            onModelActiveChange={onModelActiveChange}
            onCreateGeneration={onCreateGenerationHandler}
            onEditGeneration={onEditGenerationHandler}
            onGenerationActiveChange={onGenerationActiveChange}
            onDeleteGeneration={() => ({})} // TODO: next remove
            onDeleteModel={() => ({})} // TODO: next remove
            onCreateCompleteSet={onCreateCompleteSetHandler}
            onEditCompleteSet={onEditCompleteSetHandler}
            onDeleteCompleteSet={onDeleteCompleteSetHandler}
            onOpenGeneration={onOpenGenerationHandler}
            onSort={handleSort}
            onSearch={(_, value) => {
              setSearchModel(value || "");
            }}
          />
          <StyledPagination
            onChange={setPage}
            current={paginationParams.page}
            pageSize={10}
            total={getModelListResponse?.data?.meta?.itemCount || 0}
          />
        </>
      ),
    },
    {
      name: t("models.logs") || "",
      slug: "logs",
      content: (
        <PermissionsAdapter
          resourceName="model.log"
          resourceAction="read"
          fallback={() => (
            <Placeholder>{t("models.noAccess") || ""}</Placeholder>
          )}
        >
          <ModelsLogsTab isTabChanged={isTabChanged}/>
        </PermissionsAdapter>
      ),
    },
    {
      name: t("models.directoryExport") || "",
      slug: "dicts-export",
      content: (
        <PermissionsAdapter
          resourceName="model.dictionary"
          resourceAction="read"
          fallback={() => (
            <Placeholder>{t("models.noAccess") || ""}</Placeholder>
          )}
        >
          <ModelsExportDictTab onSubmit={() => handleExportModels()}/>
        </PermissionsAdapter>
      ),
    },
  ];

  const dealerTabs: ITab[] = [
    {
      name: t("models.title") || "",
      slug: "models",
      content: (
          <>
            <ModelsListTab
                modelListData={modelList}
                onCreateModel={onCreateModelHandler}
                onEditModel={onEditModelHandler}
                onModelActiveChange={onModelActiveChange}
                onCreateGeneration={onCreateGenerationHandler}
                onEditGeneration={onEditGenerationHandler}
                onGenerationActiveChange={onGenerationActiveChange}
                onDeleteGeneration={() => ({})} // TODO: next remove
                onDeleteModel={() => ({})} // TODO: next remove
                onCreateCompleteSet={onCreateCompleteSetHandler}
                onEditCompleteSet={onEditCompleteSetHandler}
                onDeleteCompleteSet={onDeleteCompleteSetHandler}
                onOpenGeneration={onOpenGenerationHandler}
                onSort={handleSort}
                onSearch={(_, value) => {
                  setSearchModel(value || "");
                }}
            />
            <StyledPagination
                onChange={setPage}
                current={paginationParams.page}
                pageSize={10}
                total={getModelListResponse?.data?.meta?.itemCount || 0}
            />
          </>
      ),
    },

  ];

  const { activeTab, setActiveTab } = useActiveTab(tabs);

  useEffect(() => {
    if (activeTab.name !== isTabChanged?.name) {
      setIsTabChanged(activeTab);
    }
  }, [activeTab]);


  return (
    <DashboardLayout
      title={t("models.title") || ""}
      description={t("models.description") || ""}
    >
      <Tabs
        tabs={isDealerType ? dealerTabs : tabs}
        activeTab={activeTab}
        onChange={setActiveTab}
        type="card"
      />

      {activeTab.slug === "models" ? (
        <ModelsListFooter
          onRefreshModels={refetchModelList}
          isLoadingRefreshModels={isLoadingModelList || isRefetchingModelList}
          dataModelListUpdatedAt={dataModelListUpdatedAt}
        />
      ) : (
        <></>
      )}
      {/*<UserInfoModal {...modalState}/>*/}
    </DashboardLayout>
  );
};
