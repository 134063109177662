import { PermissionsAdapter } from "@/adapters/shared/PermissionsAdapter";
import { ChatModal, IMessageParams } from "@/components/Audits/ChatModal";
import { useGetUserList } from "@/helpers/api/users/hooks";
import {
  useCreateWarrantyPlan,
  useDeleteWarrantyPlan,
  useEditWarrantyPlan,
  useEditWarrantyTaskStatus,
  useGetWarrantyPlanTasksFilling,
  useSendPlan,
} from "@/helpers/api/warranty-audit/plan/hooks";
import {
  TStatusType,
  TWarrantyAuditTaskDto,
  TWarrantyEditStatusRequest,
} from "@/helpers/api/warranty-audit/plan/types";
import { TGetWarrantyByIdResponse } from "@/helpers/api/warranty-audit/types";
import { ContextProfile } from "@/hooks/contexts/useProfile";
import { useModal } from "@/hooks/useModal";
import { useNotification } from "@/hooks/useNotification";
import { usePagination } from "@/hooks/usePagination";
import { Button } from "@/packages/button/Button";
import { ButtonText } from "@/packages/buttonText/ButtonText";
import { Paragraph } from "@/packages/paragraph/Paragraph";
import { CreateIcon } from "@/pages/AuditCreatePlan";
import React, { useContext, useMemo, useState } from "react";
import { AuditWarrantyTableAdapter } from "../../AuditWarrantyTableAdapter";
import { CommentModal } from "./CommentModal";

import { getDefaultWarrantyPlanTableHeaders, getDefaultWarrantyPlanTableHeadersView, } from "./helpers";
import { StyledButtons, StyledDescription } from "./index.styles";
import { ModalConfirm } from "@/components/AuditWarranty/ModalConfirm";
import { useTranslation } from "react-i18next";
import { warrantyChatBtn } from "@/pages/AuditWarrantyPlan/utils";
import { useUserInfoModel } from "@/components/UserInfoModal/provider";
import { StyledParagraph } from "@/components/AuditWarranty/FillWarrantyStages/Violations/index.styles";
import { useReadAllAuditGaranteeMessage } from "@/helpers/api/audit-chat/hooks";

interface IViolations {
  id: number;
  auditInfo: TGetWarrantyByIdResponse;
  openChat: (params: IMessageParams) => void;
  updateInfo: () => void;
}

export const Plan: React.FC<IViolations> = (props) => {
  const { id, auditInfo, updateInfo } = props;
  const {
    state: { profile },
  } = useContext(ContextProfile);
  const { t } = useTranslation();
  const isDealerKia = profile?.type === "kia";

  const { createNotificationError, createNotificationSuccess } =
    useNotification();
  const { mutate: createTask, isLoading: isTaskCreating } =
    useCreateWarrantyPlan();
  const {
    data: planTasks,
    refetch: updateTaskList,
    isLoading,
  } = useGetWarrantyPlanTasksFilling({ auditId: id });

  const [slugToSend, setSlugToSend] = useState<TStatusType | undefined>()

  const {
    modalState: ChatModalState,
    openModal: ChatModalOpen,
    closeModal: ChatModalClose,
  } = useModal();

  const {
    modalState: ConfirmModalState,
    openModal: ConfirmModalOpen,
    closeModal: ConfirmModalClose,
  } = useModal();

  const {
    modalState: ConfirmModalSendPlanState,
    openModal: ConfirmModalSendPlanOpen,
    closeModal: ConfirmModalSendPlanClose,
  } = useModal();

  const { mutate: sendPlan, isLoading: isPlanSending } = useSendPlan();
  const { mutate: deletePlan } = useDeleteWarrantyPlan();
  const { mutate: editPlan } = useEditWarrantyPlan();
  const { setPage, paginationParams } = usePagination();
  const [selectedTask, setSelectedTask] =
    useState<TWarrantyAuditTaskDto | null>(null);

  const { mutate: editStatus } = useEditWarrantyTaskStatus();
  const { data: responsible } =
    useGetUserList({
      dealerId: auditInfo.dealerId,
      permissionSlug: "guarantee.dealer-violations-actions",
      permissionAction: "update",
    });

  const {
    modalState: ReasonModalState,
    openModal: ReasonModalStateOpen,
    closeModal: ReasonModalStateClose,
  } = useModal();

  const success = (message?: string) => ({
    onSuccess: () => {
      updateTaskList();
      updateInfo();
      handleCloseChat();
      ReasonModalStateClose();
      message && createNotificationSuccess(message);
    },
    onError: (error) =>
      createNotificationError(error.response?.data?.message || error.message),
  });

  const handleCreateTask = () => {
    createTask({
      data: { auditId: id },
      ...success(),
    });
  };

  const handleEditPlan = ({ taskId, request }) => {
    editPlan({
      id: taskId,
      data: request,
      ...success(),
    });
  };

  const handleDelete = (id: number) => {
    deletePlan({
      id,
      ...success(t("warrantyAudits.list.taskDeleted") || ""),
    });
  };

  const handleOpenChat = (
    params: IMessageParams,
    task: TWarrantyAuditTaskDto
  ) => {
    setSelectedTask(task);
    ChatModalOpen();
  };

  const handleCloseChat = () => {
    ChatModalClose();
    updateTaskList();
  };

  const handleEditStatus = (
    taskId: number,
    request: TWarrantyEditStatusRequest,
    message?: string
  ) => {
    editStatus({ taskId, isDealerKia, data: request, ...success(message) });
  };

  const { dispatch } = useUserInfoModel();


  const isEnd = auditInfo?.stage === "Завершен";
  const headers = useMemo(
    () =>
      isEnd
        ? getDefaultWarrantyPlanTableHeadersView({
          t,
          isDealerKia,
          responsible: responsible?.data,
          onChangeStatus: handleEditStatus,
          openChat: handleOpenChat,
          openUserModal: dispatch,
          auditInfo,
        })
        : getDefaultWarrantyPlanTableHeaders({
          t,
          openChat: handleOpenChat,
          onEditField: handleEditPlan,
          onDelete: handleDelete,
          responsible: responsible?.data,
          openUserModal: dispatch,
          auditInfo,
        }),
    [isEnd, isDealerKia, responsible]
  );

  const buttonTamplate: {
    [key: string]: {
      [key: string]: {
        disabled?: boolean;
        text?: string;
        slug?: TStatusType;
        description?: string;
        isSecondary?: boolean;
        customCb?: () => void;
      }[];
    };
  } = {
    kia: {
      "Не начат": [],
      "Заполнение": [],
      "Отправлен дилеру": [
        {
          disabled: false,
          slug: "send-to-dealer",
          text: "warrantyAudits.list.sendDealer",
          customCb: function () {
            setSlugToSend(this.slug);
            ConfirmModalSendPlanOpen();
          }
        },
      ],
      "Заполнение ПД дилером": [
        {
          disabled: true,
          slug: "send-to-dealer",
          text: "warrantyAudits.list.sendDealer",
          description: "warrantyAudits.list.sendedDealer",
        },
      ],
      "Заполнение ПД": [
        {
          disabled: false,
          slug: "reject-plan",
          text: "warrantyAudits.list.reject",
          isSecondary: true,
          customCb: () => {
            ReasonModalStateOpen();
          },
        },
        {
          disabled: false,
          slug: "accept-plan",
          text: "warrantyAudits.list.accept",
          customCb: function () {
            setSlugToSend(this.slug);
            ConfirmModalSendPlanOpen();
          }
        },
      ],
      "Завершен": [],
    },
    dealer: {
      "Не начат": [],
      "Заполнение": [],
      "Отправлен дилеру": [],
      "Заполнение ПД дилером": [
        {
          disabled: false,
          text: "warrantyAudits.list.sendAuditor",
          slug: "send-to-auditor",
          customCb: function () {
            setSlugToSend(this.slug);
            ConfirmModalSendPlanOpen();
          }
        },
      ],
      "Заполнение ПД": [
        {
          disabled: true,
          text: "warrantyAudits.list.sendAuditor",
          slug: "send-to-auditor",
          description: "warrantyAudits.list.sendAuditorDescr",
        },
      ],
      "Завершен": [],
    },
  };

  const button =
    buttonTamplate[isDealerKia ? "kia" : "dealer"][auditInfo?.stage];

  return (
    <>
      {
        !isLoading &&
        planTasks?.data?.data &&
        planTasks?.data?.data.length > 0 ?
          <AuditWarrantyTableAdapter
            isCutVerticalPadding
            setPage={setPage}
            withOverflow
            autoOverflow
            paginationParams={{ ...paginationParams, limit: 0 }}
            data={planTasks?.data?.data || []}
            // @ts-ignore
            headers={headers}
            isLoading={isLoading}
            CreateRow={() =>
              !isEnd ? (
                <PermissionsAdapter
                  resourceName="guarantee.task-managment"
                  resourceAction="create"
                >
                  <ButtonText
                    isIconLeft={true}
                    isDisabled={isTaskCreating}
                    icon={<CreateIcon size={16} name="plus"/>}
                    onClick={handleCreateTask}
                    isLoadingLeft={isTaskCreating}
                  >
                    {t("warrantyAudits.list.add") || ""}
                  </ButtonText>
                </PermissionsAdapter>
              ) : (
                <></>
              )
            }
          />
          :
          <StyledParagraph>
            <Paragraph size={14}>
              {t("common.noFilled") || ""}
            </Paragraph>
          </StyledParagraph>
      }
      {button.length && !isEnd ? (
        <>
          <StyledButtons>
            {button?.map((item, index) => (
              <div>
                <Button
                  key={index}
                  theme={item.isSecondary ? "secondary" : "primary"}
                  color="brand"
                  size="small"
                  isDisabled={!!item?.disabled}
                  isLoading={isPlanSending}
                  onClick={() =>
                    item?.customCb
                    && item.customCb()
                  }
                >
                  {t(item?.text || "") || ""}
                </Button>
                {item.description ? (
                  <StyledDescription>
                    <Paragraph size={10} color="gray">
                      {t(item.description) || ""}
                    </Paragraph>
                  </StyledDescription>
                ) : (
                  <></>
                )}
              </div>
            ))}
          </StyledButtons>
        </>
      ) : (
        <></>
      )}
      {selectedTask ? (
        <ChatModal
          readAllAuditMessage={useReadAllAuditGaranteeMessage}
          isOpen={ChatModalState.isOpen}
          params={{ taskId: selectedTask.id }}
          onClose={handleCloseChat}
          isWarranty={true}
          btnCallback={warrantyChatBtn({
            isDealerKia,
            t,
            selectedTask,
            handleEditStatus,
            ConfirmModalOpen,
          })}
        />
      ) : (
        <></>
      )}
      <CommentModal
        isOpen={ReasonModalState.isOpen}
        onClose={ReasonModalStateClose}
        onSubmit={({ reason }) =>
          sendPlan({
            auditId: id,
            reason,
            type: "reject-plan",
            ...success("План действия отклонен"),
          })
        }
      />
      <ModalConfirm
        isOpen={ConfirmModalState.isOpen}
        text={t("warrantyAudits.list.confirmPlan") || ""}
        onCancel={ConfirmModalClose}
        onSubmit={() => {
          if (selectedTask?.id) {
            ConfirmModalClose();
            handleEditStatus(
              selectedTask?.id,
              { status: "accepted" },
              t("warrantyAudits.list.accept_2") || ""
            );
          }
        }}
      />
      <ModalConfirm
        title={'Подтвердите свои действия'}
        isOpen={ConfirmModalSendPlanState.isOpen}
        text={'Действие необратимо. Вы уверены?'}
        onCancel={ConfirmModalSendPlanClose}
        onSubmit={() => {
          if (slugToSend) {
            ConfirmModalSendPlanClose();
            sendPlan({
              auditId: id,
              type: slugToSend,
              ...success(),
            })
          }
        }}
      />
    </>
  );
};
